import React from 'react';

function App() {
  return (
    <>
      <section className="md:flex items-center bg-[#623CEA] lg:max-h-[100vh] overflow-hidden">
        <section className="md:w-1/2 p-9">
          <h2 className="text-white md:text-5xl text-3xl font-extrabold md:leading-tight leading-10">
            Sua empresa pode proporcionar educação
            gratuita investindo em publicidade.
          </h2>
        </section>

        <section className="md:w-1/2 self-start">
          <img src="images/grid.png" alt="people" className="w-full" />
        </section>
      </section>

      <section className="flex flex-col lg:flex-row bg-slate-50 lg:max-h-[700px] overflow-hidden">

        <section className="w-full lg:w-2/3 pb-9">
          <h2 className="text-[#623CEA] xl:text-8xl md:text-7xl text-4xl md:mt-[80px] font-black">

            <span className="bg-[#623CEA] w-full block text-white py-2 pl-9">
              Conhecimento
            </span>

            <span className="block pl-9 pr-12">
              precisa ser
              <br />
              pago?
            </span>
          </h2>

          <p className="md:text-xl text-lg md:mt-12 mt-8 leading-8 xl:pr-[180px] lg:pr-[120px] pl-9 pr-9">
            Começamos como uma escola de inglês e nos deparamos com um mercado tradicional
            pautado em vendas de materiais e cursos. Um sistema que limita o acesso a um bem
            maior: acesso ao conhecimento. Nosso objetivo foi pensar em como criar um modelo
            onde as pessoas pudessem ter acesso à educação sem precisar pagar.
          </p>
        </section>

        <section className="w-full lg:w-1/3 bg-[#623CEA] lg:p-0 px-9">
          <img src="images/thinking.png" alt="flow" className="w-full xl:ml-[-170px] lg:ml-[-100px]" />
        </section>

      </section>

      <section className="flex flex-col lg:flex-row lg:max-h-[700px] overflow-hidden">
        <section className="lg:w-1/2 w-full pb-9 bg-[#14c4BE] text-white flex flex-col justify-center">

          <span className="px-9 pt-9 text-2xl font-semibold mb-2 block">O maior problema da educação é a</span>

          <h2 className="xl:text-7xl md:text-6xl text-4xl px-9 font-extrabold xl:leading-tight md:leading-tight ">
            Desigualdade
            <br />
            social.
          </h2>

          <h2 className="xl:text-7xl md:text-6xl text-4xl font-extrabold mt-10">
            <span className="bg-[white] text-[#14C4BE] block px-9 py-2">Sem dinheiro,</span>
            <br />
            <span className="px-9 xl:mt-[-60px] lg:mt-[-40px] md:mt-[-40px] mt-[-30px] block">Sem acesso!</span>
          </h2>
        </section>

        <section className="lg:w-1/2 w-full flex items-center">
          <img src="images/inequality.png" alt="flow" className="w-full" />
        </section>
      </section>

      <section>
        <section className="p-9 bg-[#623CEA]">
          <h2 className="text-white md:text-5xl text-4xl font-extrabold">Match perfeito</h2>
        </section>

        <section className="md:p-9 p-3">
          <img src="images/flow.png" alt="flow" className="w-full md:block hidden" />
          <img src="images/m1-flow.png" alt="flow" className="md:hidden block w-full" />
        </section>
      </section>

      <section className="flex flex-col lg:flex-row lg:max-h-[700px] overflow-hidden mt-9">
        <section className="lg:w-1/2 w-full pb-9 bg-slate-50 text-[#623CEA] flex flex-col justify-center items-center">
          <div>
            <span className="px-9 pt-9 text-2xl font-semibold block">
              Comunique-se mensalmente
              <br />
              com nossa audiência de
            </span>

            <h2 className="xl:text-7xl md:text-6xl text-5xl mt-3">
              <span className="text-[#623CEA] block px-9 font-black text-9xl">148</span>
              <br />
              <span className="px-9 xl:mt-[-80px] lg:mt-[-70px] md:mt-[-60px] mt-[-50px] block font-bold">mil pessoas</span>
            </h2>
          </div>
        </section>

        <section className="lg:w-1/2 w-full flex items-center">
          <img src="images/people.png" alt="people" className="w-full" />
        </section>
      </section>

      <section className="flex flex-col lg:flex-row lg:max-h-[700px] overflow-hidden">
        <section className="bg-[#623CEA] lg:w-2/3 w-full lg:py-9 lg:pl-9 lg:pr-[180px] p-9 flex items-center">
          <h2 className="text-white md:text-5xl text-3xl font-extrabold md:leading-tight leading-10">
            Nossa principal playlist com o Curso Completo,
            já passou de 1,3 milhões de visualizações.
          </h2>
        </section>

        <section className="lg:w-1/3 w-full bg-white">
          <img src="images/mockup.png" alt="mockup" className="lg:min-w-[700px] w-full lg:ml-[-180px] ml-10" />
        </section>
      </section>

      <section className="flex flex-col lg:flex-row lg:max-h-[700px] overflow-hidden">
        <section className="bg-[#5534cc] lg:w-1/2 w-full p-9 flex items-center">
          <h2 className="text-white md:text-5xl text-3xl font-extrabold md:leading-tight leading-10">
            Em apenas 2 anos de
            open school crescemos exponencialmente nosso alcance.
          </h2>
        </section>

        <section className="lg:w-1/2 w-full bg-white lg:p-[100px] p-10">
          <img src="images/graph.png" alt="graph" className="w-full" />
        </section>
      </section>

      <section className="flex flex-col lg:flex-row lg:max-h-[700px] overflow-hidden">
        <section className="bg-[#623CEA] lg:w-1/2 w-full p-9 flex flex-col justify-center">
          <h2 className="text-white md:text-6xl text-3xl font-extrabold md:leading-20 leading-10">
            Queremos
            <br />
            crescer essa
            ideia
          </h2>
          <p className="md:text-3xl text-xl md:mt-7 mt-4 text-white md:leading-snug ">
            Procuramos empresas
            que valorizam a educação e buscam promover juntos o modelo de Open School.
          </p>
        </section>

        <section className="lg:w-1/2 w-full bg-white">
          <img src="images/partner.png" alt="partner" className="w-full" />
        </section>
      </section>

      <section className="flex flex-col relative lg:h-screen lg:flex-row lg:max-h-[800px] overflow-hidden">
        <section className="bg-[#5534cc] lg:w-1/2 w-full p-9 flex flex-col justify-center">
          <h2 className="text-white md:text-6xl text-3xl font-extrabold md:leading-20 leading-10">
            Queremos contar com você para essa jornada!
          </h2>
          <p className="md:text-3xl text-xl mt-8 text-white md:leading-snug font-bold">
            Vamos bater um papo?
          </p>
          <p className="md:text-3xl text-xl md:mt-[100px] mt-[60px] text-white md:leading-snug font-bold">
            Eduardo Tebexerini
            <br />
            <br />
            <span className="flex items-center gap-3 font-semibold md:text-2xl text-xl mb-2">
              <img src="images/wpp.png" alt="wpp-icon" className="md:w-[30px] w-[25px]" />
              (11) 96713-6492
            </span>
            <span className="flex items-center gap-3 font-semibold md:text-2xl text-xl">
              <img src="images/mail.png" alt="mail-icon" className="md:w-[30px] w-[25px]" />
              eduardo@englishbay.com.br
            </span>
          </p>
        </section>

        <section className="lg:w-1/2 w-full bg-[#5534cc] d-none hidden lg:block">
          <img src="images/logo.png" alt="partner" className="h-full absolute right-0" />
        </section>
      </section>
    </>
  );
}

export default App;
